
body {
  background-image: url('./assets/image/background-one-img.png');
  background-size: cover;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-weight: 400;
  position: relative;
  background-color: #1f2833 !important;
  color: #00a4c1 !important;
  font-family: 'Ubuntu', serif !important;
}

.navbarlogo {
  width: 40px;
}

.social-icon {
  width: 30px;
}




